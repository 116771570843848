import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default ({ image, title, description, included, link }) => {
  const isExternal = link ? link.startsWith('http') : false
  return (
    <ActivityCard>
      <Image
        image={image.childImageSharp.gatsbyImageData.images.fallback.src}
      />
      {included ? (
        <Included>Included</Included>
      ) : (
        <NotIncluded>Optional</NotIncluded>
      )}
      <Title>{title}</Title>
      <Description>{description}</Description>
      {link && !isExternal ? (
        <InternalButton to={link}>Learn More</InternalButton>
      ) : link && isExternal ? (
        <Button target="_blank" href={link}>
          Learn More
        </Button>
      ) : null}
    </ActivityCard>
  )
}

const ActivityCard = styled.div`
  flex-direction: column;
  margin: 20px 20px 0;
  width: 320px;
  height: 430px;
  display: flex;
  justify-content: flex-start;
  background: white;
  border-radius: 6px;
`
const Included = styled.p`
  margin: -180px 0 145px auto;
  padding: 17px 15px 17px 20px;
  font-size: 10pt;
  color: #2c9c5c;
  line-height: 0;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 17px 0 0 17px;
  font-style: italic;
  font-weight: 400;
`
const NotIncluded = styled.p`
  margin: -180px 0 145px auto;
  padding: 17px 15px 17px 20px;
  font-size: 10pt;
  color: ${(props) => props.theme.primaryColor};
  line-height: 0;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 17px 0 0 17px;
  font-style: italic;
  font-weight: 400;
`
const Image = styled.div`
  height: 200px;
  width: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center center;
  border-radius: 6px 6px 0 0;
`
const Title = styled.h2`
  margin: 25px 20px 10px;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 14pt;
  font-weight: 600;
  color: ${(props) => props.theme.primaryColor};

  @media (max-width: ${(props) => props.theme.desktop}px) {
    text-align: center;
  }
`
const Description = styled.div`
  margin: 0 20px 30px;
  padding: 0;
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 12pt;
  line-height: 18pt;
  color: ${(props) => props.theme.secondaryColor};

  @media (max-width: ${(props) => props.theme.desktop}px) {
    text-align: center;
  }
`
const Button = styled.a`
  border: 2px solid ${(props) => props.theme.purple};
  color: ${(props) => props.theme.purple};
  line-height: 0;
  padding: 15px 35px;
  margin: 0 auto 20px;
  text-align: center;
  border-radius: 25px;
  font-weight: 400;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.purple};
    color: white;
  }

  @media (max-width: ${(props) => props.theme.phone}px) {
    padding: 20px 35px;
    border-radius: 20px;
  }
`
const InternalButton = Button.withComponent(Link)
